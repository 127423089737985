<template>
  <div class="reactions">
    <transition-group appear @before-enter="emojiBeforeEnter" @enter="emojiEnter" class="emojis">
      <div v-for="(el, i) in emojis" :key="`emoji-${i-1}`" class="emoji-wrapper">
        <div class="emoji">
          <img :src="`/assets/reactions/reaction-${el.reaction}.webp`">
        </div>
        <div v-if="showUsername" class="player" :style="{borderColor: `#${el.color}`}">
          {{ el.username }}<br />
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import gsap from "gsap";

export default {
  data() {
    return {
      showUsername: true,
      emojis: [],
      emojisTimeOuts: [],
    };
  },
  computed: {
    players() {
      return this.$store.state.space.players.filter(x => !x.queue)
    },
  },
  methods: {
    emojiBeforeEnter(el) {
      el.style.scale = .5;
      el.style.right = `${300 + Math.floor(Math.random() * 40) * (Math.random() > 0.5 ? 1 : -1)}px`
    },
    emojiEnter(el) {
      gsap.to(el, {
        duration: .25,
        scale: 1,
      })
      gsap.to(el, {
        x: Math.floor(Math.random() * 100) * (Math.random() > 0.5 ? 1 : -1),
        duration: 3,
        ease: "elastic.out(1.5, 0.3)",
      })
      gsap.to(el, {
        scale: 1.2,
        duration: .2,
        delay: 2.8,
        ease: "power1.in",
      })
      gsap.to(el, {
        y: -1000 + Math.floor(Math.random() * 400),
        duration: 3,
        ease: "power1.out",
        onComplete: () => {
          el.style.opacity = 0
          this.emojisTimeOuts.push(setTimeout(() => this.emojisTimeOuts.shift(), 10000))
        }
      })
    },
  },
  mounted() {
  },
  beforeDestroy() {
    this.emojisTimeOuts.forEach(to => {
      clearTimeout(to)
    })
    this.emojisTimeOuts = []
  },
  sockets: {
    SendReaction(data) {
      if (data.type == 'emoji') this.emojis.push(data)
    },
  }

};
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.reactions {

  .emoji-wrapper {
    position: absolute;
    bottom: -4rem;
    right: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.5rem;
    gap: 18px;
  }

  .emoji {
    width: 70px;
    height: 70px;

    img {
      width: 100%;
    }
  }

  .player {
    max-width: 143px;
    overflow: hidden;
    padding: 0.3em;
    border-style: solid;
    border-width: 3px;
    border-radius: $general-border-radius;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    text-overflow: ellipsis;
    white-space: pre;
    background: white;
  }

}
</style>